<template>
  <f7-navbar>
    <f7-nav-left>
      <f7-link v-if="type === 'back' && isBack" @click.stop="$h.goBack()">
        <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width />
      </f7-link>
      <f7-link v-else-if="type === 'backOrder'">
        <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width @click="backOrder()" />
      </f7-link>
      <f7-link v-else-if="type === 'backProfile'">
        <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width @click="backProfile()" />
      </f7-link>
      <LeftPanelNavigationComponent v-else />
    </f7-nav-left>
    <f7-nav-title> {{ title }} </f7-nav-title>
    <f7-nav-right>
      <f7-link class="sett-btn" v-if="settings === true" href="/profile/settings/">
        <div class="sett-text">{{ $t.getTranslation("LBL_SETTINGS") }}</div>
        <font-awesome-icon :icon="['far', 'cog']" fixed-width />
      </f7-link>
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import { defineComponent, computed, inject, defineAsyncComponent } from "vue";
import { useStore } from "@/store";

import { f7 } from "framework7-vue";

// import LeftPanelNavigationComponent from "@/components/navigations/LeftPanelNavigationComponent.vue";

const LeftPanelNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "left-panel-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/LeftPanelNavigationComponent.vue"));

export default defineComponent({
  name: "ProfileNavigationComponent",
  components: {
    LeftPanelNavigationComponent,
  },
  props: {
    title: String,
    type: String,
    settings: { type: Boolean, default: true },
    isBack: { type: Boolean, default: true },
    f7router: { type: Object, default: null },
  },
  setup(props) {
    const $f7router = f7.views.get("#main-view").router;

    const store = useStore();

    const activeLink = computed(() => store.getters["page/getActiveLink"]);
    const backOrder = () => {
      window.location.href = "/profile/order/?mode=all";
    };

    const backProfile = () => {
      $f7router.navigate({ name: "profilePage" });
    };

    return {
      activeLink,
      backOrder,
      backProfile,
      store,
    };
  },
});
</script>

<style scoped>
.sett-btn {
  position: relative;
  display: inline-block;
}
.sett-text {
  display: block;
  width: 100%;
  font-size: 15px;
  color: var(--text-black);
  font-weight: 700;
  height: auto;
  line-height: 1.4em;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left: 5px;
  margin: 0px;
  box-sizing: border-box;
}

/* .sett-btn .sett-text {
  visibility: hidden;
  width: 80px;
  height: 40px;
  display: flex;
  background-color: #1a64ca;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 9999;
  top: 5px;
  right: 45px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
}

.sett-btn .sett-text::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #1a64ca;
}

.sett-btn:hover .sett-text {
  visibility: visible;
} */
</style>
